import { DynamicLocalizationKey } from "@gtmhub/shared/models";
import { TrackingMetadata } from "@webapp/analytics/models/analytics.model";
import { RolePermissionGroupName, RolePermissionProductName, RolePermissionSubGroupName, RolePermissionSubGroupState } from "@webapp/roles/models/roles.models";
import { SearchCollection } from "@webapp/search/models/search.models";
import { IAccess, IPrincipalKind } from "@webapp/sessions/models/sessions.model";

export type Permission =
  | "AccessGoals"
  | "AccessPrivateGoals"
  | "AccessInsightboards"
  | "AccessKPIs"
  | "AccessPeople"
  | "AccessReportsAndDataSourceFilters"
  | "AwardBadges"
  | "CreateTags"
  | "ManageAccountNotifications"
  | "ManageApiTokens"
  | "ManageApplications"
  | "ManageBadges"
  | "ManageBilling"
  | "ManageActivityHistory"
  | "ManageConfiguration"
  | "ManageData"
  | "ManageKPIs"
  | "ManageGoals"
  | "ManagePeople"
  | "ManageReflections"
  | "ManageSessions"
  | "ManageTasks"
  | "ManageUserInvitations"
  | "ManageUsers"
  | "PostAnnouncement"
  | "ManageMetricSnapshots"
  | TeamGranularPermissions
  | UsersGranularPermissions
  | RolesGranularPermissions
  | UserProvisioningGranularPermissions
  | SingularityPermissions
  | ManageSessionsGranularPermissions
  | ManageKPIsGranularPermissions
  | GoalsGranularPermissions
  | WhiteboardsGranularPermissions
  | PlatformIntelligenceGranularPermissions
  | CommentsAndReactionsGranularPermissions;

type TeamGranularPermissions = "team:create" | "team:edit" | "team:delete" | "team:manage_activation" | "team:manage_members";

type UsersGranularPermissions =
  | "users:access"
  | "users:invite"
  | "users:edit_details"
  | "users:edit_roles"
  | "users:manage_license"
  | "users:manage_activation"
  | "users:delete";

type GoalsGranularPermissions = "goals:create" | "goals:edit" | "goals:delete" | "goals:own" | "metric_snapshots:create" | "metric_own_snapshots:edit_delete";

type WhiteboardsGranularPermissions =
  | "ManageWhiteboards"
  | "ManageWhiteboardTemplates"
  | "AccessWhiteboards"
  | "whiteboards:create"
  | "whiteboards:edit"
  | "whiteboards:delete";

type PlatformIntelligenceGranularPermissions = "AccessPlatformIntelligence";

type CommentsAndReactionsGranularPermissions = "comments_and_reactions:manage_own" | "comments:delete";

type RolesGranularPermissions = "roles:create" | "roles:edit" | "roles:delete";
type UserProvisioningGranularPermissions = "scim_integrations:manage" | "sso_role_mappings:manage";

type SingularityPermissions = "AccessStrategies" | "AccessSignals" | "AccessDiscoveries";

type ManageSessionsGranularPermissions = "sessions:create" | "sessions:edit" | "sessions:delete";

type ManageKPIsGranularPermissions =
  | "kpis:own"
  | "kpis:edit"
  | "kpis:create"
  | "kpis:delete"
  | "kpi_snapshots:create"
  | "kpi_own_snapshots:delete"
  | "kpi_snapshots:delete";

export type PermissionDTO = string[];

export interface IUsersWithAllowedActionPerItem {
  userIds: string[];
  allUsersHaveTheAction: boolean;
}

export type ItemWithAccessType = "session" | "dashboard" | "kpi" | "connection" | "datasource";

export enum SimplePermissionsAccessType {
  publicRead = "publicRead",
  publicUpdate = "publicUpdate",
  privateAccess = "private",
  restricted = "restricted",
}

export const simplePermissionsAccessTypeToTrack = new Map<SimplePermissionsAccessType, string>([
  [SimplePermissionsAccessType.privateAccess, "only_you"],
  [SimplePermissionsAccessType.restricted, "invited_only"],
  [SimplePermissionsAccessType.publicRead, "everyone_can_view"],
  [SimplePermissionsAccessType.publicUpdate, "everyone_can_edit"],
]);

export interface SimplePermissionsConfig {
  access: IAccess;
  ownerId: string;
  showPermissions: boolean;
  triggerButtonAriaLabel: string;
  compactMode?: boolean;
  isGrayBackground?: boolean;
  toggleHandler?(arg: { isVisible: boolean }): void;
  accessChangeHandler?(arg: { access: IAccess }): void;
  shareableLink?: ShareableLink;
}

export type AccessOption = {
  icon: string;
  labelKey: string;
  value: SimplePermissionsAccessType;
};

export const principalKindByCollectionMap = new Map<SearchCollection, IPrincipalKind>([
  ["users", "user"],
  ["teams", "team"],
  ["roles", "role"],
]);

export interface ShareableLink {
  shouldDisplay?: boolean;
  url: string;
  copyLinkHandler(meta?: TrackingMetadata): void;
}

export type AccessChange = { access: IAccess; accessType?: SimplePermissionsAccessType };

export type PermissionActionDTO = {
  name: Permission;
  title: string;
  titleKey: DynamicLocalizationKey;
  description: string;
  descriptionKey: DynamicLocalizationKey;
  product: RolePermissionProductName;
  group: RolePermissionGroupName;
  groupKey: DynamicLocalizationKey;
  requiredPermissions: DynamicLocalizationKey[] | null;
  recommendedPermissions?: Permission[];
  prerequisitePermissions?: Permission[];
  subGroup?: RolePermissionSubGroupName;
  parentPermission?: PermissionActionDTO["name"];
  childPermissions?: (Permission | RolePermissionSubGroupName)[];
};

export type PermissionActionBaseVM = Omit<PermissionActionDTO, "name" | "titleKey" | "descriptionKey"> & {
  name: Permission | RolePermissionSubGroupName;
  titleKey: DynamicLocalizationKey | string;
  descriptionKey: DynamicLocalizationKey | string;
};

type PermissionActionLocalization = {
  localizedGroup: string;
  localizedTitle: string;
  localizedDescription: string;
  localizedPermissionWarning: string;
};

type PermissionActionSubGroupData =
  | {
      /**
       * @property {boolean} isSubGroupRoot
       * When false, it indicates that a permissionAction is an actual permission;
       * When true, it indicates that a permissionAction is NOT an actual permission but acts just as an union for some actual granular permissions beneath it.
       */
      isSubGroupRoot?: false;
    }
  | {
      isSubGroupRoot: true;
      subGroupInitialState: RolePermissionSubGroupState;
    };

export type PermissionActionVM = PermissionActionBaseVM & PermissionActionLocalization & PermissionActionSubGroupData;

export type QuantiveProductName = "Platform" | "Results" | "Strategy";

export type QuantiveProduct = {
  name: QuantiveProductName;
  displayName: string;
  url: string;
};
